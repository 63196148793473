<template>
  <div class="scheduleMaint1">
    <v-card class="pa-5 v-card__text" flat outlined>
      <v-row>
        <v-col cols="6">
          <v-text-field
            class="rounded-0"
            v-model="hospitalNm"
            outlined
            clearable
            label="物件名"
          ></v-text-field>
          <!-- <v-autocomplete
            v-model="hospitalNm"
            :items="hospitals"
            outlined
            label="物件名"
            chips
            disabled
          >
          </v-autocomplete> -->
        </v-col>

        <v-col cols="6" class=" ">
          <DatePicker
            v-model="workDate"
            class="datepickers"
            label="作業実施日"
            outlined
            clearable
          />
        </v-col>
      </v-row>

      <!-- *************** tab buttons *************** -->
      <v-row>
        <v-toolbar>
          <v-btn
            :class="{ activeTab: workContentFormActive }"
            @click="activeWorkContentForm()"
            >作業内容</v-btn
          >
          <v-btn
            class="mx-2"
            :class="{ activeTab: workerFormActive }"
            @click="activeWorkerForm()"
            >作業者</v-btn
          >
        </v-toolbar>
      </v-row>

      <v-form>
        <WorkContentForm v-if="workContentFormActive"></WorkContentForm>
        <WorkerForm v-if="workerFormActive"></WorkerForm>
      </v-form>

      <v-row class="justify-sm-space-between mt-5">
        <!-- ***** Signature 電子署名 *********************************** -->
        <v-col cols="6">
          <div class="cardBorder" outlined>
            <label class="cardTextFontColor">電子署名</label>
            <vueSignature :h="'300px'"></vueSignature>
          </div>
        </v-col>

        <v-card-actions class="justify-end align-end">
          <v-btn class="ma-2 grey" @click="$emit('clickClose')">
            閉じる
          </v-btn>
          <v-btn class="ma-2 success" @click="$emit('clickClose')">
            完了
          </v-btn>
        </v-card-actions>
      </v-row>

      <!-- ***** main vcard END -->
    </v-card>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import vueSignature from "vue-signature";
import WorkContentForm from "@/views/gamenImage/components/WorkContentForm.vue";
import WorkerForm from "./components/WorkerForm.vue";

export default {
  components: {
    /**
     * custom components haru import garepachi
     * yeta chai custom components haru register garne
     * ani matra mathi template vitra use garna sakincha navaye vuss ho
     */
    DatePicker,
    vueSignature,
    WorkContentForm,
    WorkerForm
  },
  data() {
    return {
      hospitals: ["病院", "病院2", "病院3"],
      hospitalNm: "病院",
      // workDate: "2025/02/01",
      workDate: "",
      workContentFormActive: true,
      workerFormActive: false
    };
  },
  computed: {},
  methods: {
    activeWorkContentForm() {
      this.workContentFormActive = true;
      this.workerFormActive = false;
    },
    activeWorkerForm() {
      this.workContentFormActive = false;
      this.workerFormActive = true;
    }
  }
};
</script>

<style>
/* yesle clear icon lai left ma lyaucha */
/* .scheduleMaint1 .leftClearTextField .v-input__append-inner {
  order: -1;
} */

/* .leftClearTextField .v-input__icon--clear {
  left: 12px;
} */

.v-toolbar {
  /* height: auto; */
}

/* icon remove pachi label milauna lai***************************** */
/* yo chai paila lekheko wala  */
/* .v-input--is-focused .v-label--active {
  left: 0px;
} */
.scheduleMaint1 .v-label--active {
  left: 0px;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}

.cardBorder {
  /* border: 1px solid rgba(0, 0, 0, 0.87); */
  /* border: 1px solid rgba(0, 0, 0, 0.6); */
  border: 1px solid rgba(0, 0, 0, 0.4);
  /* overflow: hidden; */
}

.cardTextFontColor {
  color: rgba(0, 0, 0, 0.6);
}

.activeTab {
  background-color: #81ccc4 !important;
  color: #fff !important;
}

.scheduleMaint1 .v-card__text {
  padding: 0;
}

.scheduleMaint1 .v-text-field__details {
  display: none !important;
}
</style>
