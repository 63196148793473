<template>
  <!-- <v-form> -->
  <v-card class="pa-5">
    <!-- ****************************** 1st container ************************* -->
    <v-container class="cardBorder ">
      <v-row class="align-center">
        <v-col class="" cols="10" sm="8">
          <v-text-field
            v-model="workContent1"
            class="rounded-0"
            label="作業内容"
            outlined
            clearable
            maxlength="255"
          ></v-text-field>
        </v-col>
        <v-col class="ml-15">
          <v-checkbox label="追加・修正"> </v-checkbox>
        </v-col>
      </v-row>

      <v-row class="">
        <v-col class="">
          <v-text-field
            class="rounded-0"
            label="フロア"
            outlined
            clearable
            v-model="floor2"
          ></v-text-field>
        </v-col>
        <v-col class="">
          <v-text-field
            class="rounded-0"
            label="エリア"
            outlined
            clearable
            v-model="area2"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- *** checkboxes wala row********************************* -->
      <v-row class="align-center">
        <v-col class="" cols="5" xl="6" sm="4">
          <v-text-field
            v-model="f2spot1"
            class="rounded-0"
            :label="'箇所'"
            outlined
            required
            clearable
            maxlength="255"
          />
        </v-col>

        <v-col>
          <v-row class="justify-space-around">
            <v-checkbox v-model="f2s1zumi" label="済"> </v-checkbox>
            <v-checkbox v-model="f2s1nextMonth" label="次月"> </v-checkbox>
            <v-checkbox v-model="f2s1review" label="検討"> </v-checkbox>
            <v-checkbox v-model="f2s1nextTime" label="次回"> </v-checkbox>
            <v-checkbox v-model="f2s1addCorrect" label="追加・修正">
            </v-checkbox>
          </v-row>
        </v-col>

        <!-- <v-col class="" >
          <v-checkbox v-model="f2s1zumi" label="済"> </v-checkbox>
        </v-col>
        <v-col class="" >
          <v-checkbox v-model="f2s1nextMonth" label="次月"> </v-checkbox>
        </v-col>
        <v-col class="" >
          <v-checkbox v-model="f2s1review" label="検討"> </v-checkbox>
        </v-col>
        <v-col class="" >
          <v-checkbox v-model="f2s1nextTime" label="次回"> </v-checkbox>
        </v-col>
        <v-col class="" sm="2">
          <v-checkbox v-model="f2s1addCorrect" label="追加・修正"> </v-checkbox>
        </v-col> -->
      </v-row>

      <v-row class="align-center">
        <v-col class="" cols="5" xl="6" sm="4">
          <v-text-field
            v-model="f2spot2"
            class="rounded-0"
            :label="'箇所'"
            outlined
            required
            clearable
            maxlength="255"
          />
        </v-col>

        <v-col>
          <v-row class="justify-space-around">
            <v-checkbox v-model="f2s2zumi" label="済"> </v-checkbox>
            <v-checkbox v-model="f2s2nextMonth" label="次月"> </v-checkbox>
            <v-checkbox v-model="f2s2review" label="検討"> </v-checkbox>
            <v-checkbox v-model="f2s2nextTime" label="次回"> </v-checkbox>
            <v-checkbox
              v-model="f2s2addCorrect"
              label="追加・修正"
            ></v-checkbox>
          </v-row>
        </v-col>

        <!-- <v-col class="" cols="1">
          <v-checkbox v-model="f2s2zumi" label="済"> </v-checkbox>
        </v-col>
        <v-col class="" cols="1">
          <v-checkbox v-model="f2s2nextMonth" label="次月"> </v-checkbox>
        </v-col>
        <v-col class="" cols="1">
          <v-checkbox v-model="f2s2review" label="検討"> </v-checkbox>
        </v-col>
        <v-col class="" cols="1">
          <v-checkbox v-model="f2s2nextTime" label="次回"> </v-checkbox>
        </v-col>
        <v-col class="" cols="2">
          <v-checkbox v-model="f2s2addCorrect" label="追加・修正"> </v-checkbox>
        </v-col> -->
      </v-row>
    </v-container>

    <!-- <work-content :floor="floor2" :area="asdf"></work-content> -->

    <!-- ############################ 2nd CONTAINER ################################################# -->
    <v-container class="cardBorder mt-5">
      <v-row class="align-center">
        <v-col class="" cols="10" sm="8">
          <v-text-field
            v-model="workContent2"
            class="rounded-0"
            label="作業内容"
            outlined
            clearable
            maxlength="255"
          ></v-text-field>
        </v-col>
        <v-col class="ml-15">
          <v-checkbox label="追加・修正"> </v-checkbox>
        </v-col>
      </v-row>

      <v-row class="">
        <v-col class="">
          <v-text-field
            v-model="floor1"
            class="rounded-0"
            label="フロア"
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="">
          <v-text-field
            v-model="area1"
            class="rounded-0"
            label="エリア"
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- *** checkboxes wala row*** -->
      <v-row class="align-center">
        <v-col class="" cols="5" xl="6" sm="4">
          <v-text-field
            v-model="f1spot1"
            class="rounded-0"
            :label="'箇所'"
            outlined
            required
            clearable
            maxlength="255"
          />
        </v-col>

        <v-col>
          <v-row class="justify-space-around">
            <v-checkbox v-model="f1s1zumi" label="済"> </v-checkbox>
            <v-checkbox v-model="f1s1nextMonth" label="次月"> </v-checkbox>
            <v-checkbox v-model="f1s1review" label="検討"> </v-checkbox>
            <v-checkbox v-model="f1s1nextTime" label="次回"> </v-checkbox>
            <v-checkbox
              v-model="f1s1addCorrect"
              label="追加・修正"
            ></v-checkbox>
          </v-row>
        </v-col>

        <!-- <v-col class="" cols="1">
          <v-checkbox v-model="f1s1zumi" label="済"> </v-checkbox>
        </v-col>
        <v-col class="" cols="1">
          <v-checkbox v-model="f1s1nextMonth" label="次月"> </v-checkbox>
        </v-col>
        <v-col class="" cols="1">
          <v-checkbox v-model="f1s1review" label="検討"> </v-checkbox>
        </v-col>
        <v-col class="" cols="1">
          <v-checkbox v-model="f1s1nextTime" label="次回"> </v-checkbox>
        </v-col>
        <v-col class="" cols="2">
          <v-checkbox v-model="f1s1addCorrect" label="追加・修正"> </v-checkbox>
        </v-col> -->
      </v-row>

      <v-row class="align-center">
        <v-col class="" cols="5" xl="6" sm="4">
          <v-text-field
            v-model="f1spot2"
            class="rounded-0"
            :label="'箇所'"
            outlined
            required
            clearable
            maxlength="255"
          />
        </v-col>

        <v-col>
          <v-row class="justify-space-around">
            <v-checkbox v-model="f1s2zumi" label="済"> </v-checkbox>
            <v-checkbox v-model="f1s2nextMonth" label="次月"> </v-checkbox>
            <v-checkbox v-model="f1s2review" label="検討"> </v-checkbox>
            <v-checkbox v-model="f1s2nextTime" label="次回"> </v-checkbox>
            <v-checkbox
              v-model="f1s2addCorrect"
              label="追加・修正"
            ></v-checkbox>
          </v-row>
        </v-col>

        <!-- <v-col class="" cols="1">
          <v-checkbox v-model="f1s2zumi" label="済"> </v-checkbox>
        </v-col>
        <v-col class="" cols="1">
          <v-checkbox v-model="f1s2nextMonth" label="次月"> </v-checkbox>
        </v-col>
        <v-col class="" cols="1">
          <v-checkbox v-model="f1s2review" label="検討"> </v-checkbox>
        </v-col>
        <v-col class="" cols="1">
          <v-checkbox v-model="f1s2nextTime" label="次回"> </v-checkbox>
        </v-col>
        <v-col class="" cols="2">
          <v-checkbox v-model="f1s2addCorrect" label="追加・修正"> </v-checkbox>
        </v-col> -->
      </v-row>
    </v-container>

    <v-row class="mt-0">
      <v-col class="" cols="2" sm="3">
        <v-checkbox v-model="hospitalRequest" label="病院様より要請">
        </v-checkbox>
      </v-col>
      <v-col class="" cols="2" sm="3">
        <v-checkbox v-model="selfConvenience" label="自社都合"> </v-checkbox>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="6">
        <v-text-field
          v-model="hospitalRepresentative"
          class=" rounded-0"
          outlined
          clearable
          label="病院担当者"
        ></v-text-field>
      </v-col>

      <v-col cols="6" class="">
        <DatePicker
          v-model="scheduledReviewDate"
          label="日程検討日"
          outlined
          clearable
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model="hospitalRemainReason"
          class=" rounded-0"
          outlined
          clearable
          label="病院側残理由"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model="selfRemainReason"
          class=" rounded-0"
          label="自社側残理由"
          outlined
          clearable
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- 作業内容 card 1 end ********************************* -->
  </v-card>
  <!-- </v-form> -->
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";

// import WorkContent from "./WorkContent.vue";

export default {
  components: {
    DatePicker
    // "work-content": WorkContent
  },
  data() {
    return {
      workContent1: "床面WAX洗浄",
      workContent2: "床面WAX洗浄",
      floor2: "2F",
      floor1: "1F",
      area1: "共用エリア",
      area2: "OPEエリア",
      f2spot1: "Ａ室",
      f2spot2: "Ｂ室",
      f1spot1: "待合室",
      f1spot2: "廊下",

      f2s1zumi: true,
      f2s1nextMonth: false,
      f2s1review: false,
      f2s1nextTime: false,
      f2s1addCorrect: false,
      f2s2zumi: true,
      f2s2nextMonth: false,
      f2s2review: false,
      f2s2nextTime: false,
      f2s2addCorrect: false,

      f1s1zumi: true,
      f1s1nextMonth: false,
      f1s1review: false,
      f1s1nextTime: false,
      f1s1addCorrect: false,
      f1s2zumi: false,
      f1s2nextMonth: true,
      f1s2review: false,
      f1s2nextTime: false,
      f1s2addCorrect: false,

      hospitalRequest: true,
      selfConvenience: false,
      hospitalRepresentative: "山田 太郎",
      scheduledReviewDate: "2025/03/10",
      // scheduledReviewDate: "",
      hospitalRemainReason: "床の破損による、工事のため",
      selfRemainReason: ""
    };
  }
};
</script>

<style scoped>
.cardBorder {
  /* border: 1px solid rgba(0, 0, 0, 0.87); */
  /* border: 1px solid rgba(0, 0, 0, 0.6); */
  border: 1px solid rgba(0, 0, 0, 0.4);
}
</style>
