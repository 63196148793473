<template>
  <v-card class="pb-15 workerForm">
    <!-- <div class=""> -->
    <div class="v-card__text">
      <!-- ***** timepicker row ************************************************** -->
      <v-row class="mx-5">
        <v-col class="" cols="4">
          <label>集合時間</label>
          <vue-timepicker
            class="ml-7"
            v-model="meetingTime"
            placeholder="集合時間"
            auto-scroll
            input-width="95%"
            :disabled="!edit"
          >
            <template v-slot:clearButton>
              <img src="@/assets/Clear.png" />
            </template>
          </vue-timepicker>
        </v-col>
        <v-col class="" cols="4">
          <label>開始時間</label>
          <vue-timepicker
            class="ml-7"
            v-model="startTime"
            placeholder="開始時間"
            auto-scroll
            input-width="95%"
            :disabled="!edit"
          >
            <template v-slot:clearButton>
              <img src="@/assets/Clear.png" />
            </template>
          </vue-timepicker>
        </v-col>
        <v-col class="" cols="4">
          <label>終了時間</label>
          <vue-timepicker
            class="ml-7"
            v-model="endTime"
            placeholder="終了時間"
            auto-scroll
            input-width="95%"
            :disabled="!edit"
          >
            <template v-slot:clearButton>
              <img src="@/assets/Clear.png" />
            </template>
          </vue-timepicker>
        </v-col>
      </v-row>

      <!-- *** timepicker with checkboxes wala row*** -->
      <v-row class="mx-5 align-center">
        <v-col class="" sm="4">
          <label>期社時間</label>
          <vue-timepicker
            class="ml-7"
            v-model="companyTime"
            placeholder="期社時間"
            auto-scroll
            input-width="95%"
            :disabled="!edit"
          >
            <template v-slot:clearButton>
              <img src="@/assets/Clear.png" />
            </template>
          </vue-timepicker>
        </v-col>

        <v-col>
          <v-row class="justify-space-around">
            <v-checkbox v-model="greeting" label="あいさつ"> </v-checkbox>
            <v-checkbox v-model="safetyConfirm" label="安全確認"> </v-checkbox>
            <v-checkbox v-model="noticeBoard" label="表示板"> </v-checkbox>
            <v-checkbox v-model="coneChain" label="コーン+チェーン">
            </v-checkbox>
          </v-row>
        </v-col>

        <!-- <v-col class="" cols="2">
        <v-checkbox v-model="greeting" label="あいさつ"> </v-checkbox>
      </v-col>
      <v-col class="" cols="2">
        <v-checkbox v-model="safetyConfirm" label="安全確認"> </v-checkbox>
      </v-col>
      <v-col class="" cols="2">
        <v-checkbox v-model="noticeBoard" label="表示板"> </v-checkbox>
      </v-col>
      <v-col class="" cols="2">
        <v-checkbox v-model="coneChain" label="コーン+チェーン"> </v-checkbox>
      </v-col> -->
      </v-row>

      <v-row class="mx-5">
        <v-col class="">
          <v-text-field
            class="rounded-0"
            label="その他"
            outlined
            clearable
            maxlength="255"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- </v-container> -->

      <!-- ***** 作業担当者 divider label ***** ######################################################################### -->
      <v-row
        class="mx-0 mb-0 align-center blue darken-2 grey--text text--lighten-5"
      >
        <v-col class="pa-0">
          <div class="dividerLabel py-3">
            <p class="mb-0 px-5">作業担当者</p>
          </div>
        </v-col>
      </v-row>

      <v-row class="mx-5">
        <v-col class=" " cols="4">
          <v-text-field
            class="rounded-0"
            label="作業担当者"
            outlined
            clearable
            v-model="workIncharge"
          ></v-text-field>
        </v-col>

        <v-col class="" cols="4">
          <!-- <label>休憩時間①</label>
        <vue-timepicker
          class="ml-7"
          placeholder="休憩時間①"
          auto-scroll
          input-width="95%"
          :disabled="!edit"
        >
          <template v-slot:clearButton>
            <img src="@/assets/Clear.png" />
          </template>
        </vue-timepicker> -->

          <v-text-field
            class="rounded-0"
            label="休憩時間①"
            outlined
            clearable
          ></v-text-field>
        </v-col>

        <v-col class="" cols="4">
          <!-- <label>休憩時間②</label>
        <vue-timepicker
          class="ml-7"
          placeholder="休憩時間②"
          auto-scroll
          input-width="95%"
          :disabled="!edit"
        >
          <template v-slot:clearButton>
            <img src="@/assets/Clear.png" />
          </template>
        </vue-timepicker> -->
          <v-text-field
            class="rounded-0"
            label="休憩時間②"
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mx-5">
        <v-col class="" cols="4">
          <v-text-field
            class="rounded-0"
            label="実休憩時間"
            outlined
            clearable
            suffix="H"
            v-model="wIActualBreak"
          ></v-text-field>
        </v-col>

        <v-col class="" cols="2">
          <v-checkbox v-model="wITimeCount" label="時間計算"> </v-checkbox>
        </v-col>

        <v-col class="" cols="3">
          <v-text-field
            class="rounded-0"
            label="作業時間"
            outlined
            clearable
            suffix="H"
            v-model="wIWorkHours"
          ></v-text-field>
        </v-col>

        <v-col class="" cols="3">
          <v-text-field
            class="rounded-0"
            label="残業時間"
            outlined
            clearable
            suffix="H"
            v-model="wIOvertimeHours"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- ***** 担当者 divider label ***** ######################################################################### -->
      <v-row
        class="mx-0 mb-0 align-center blue darken-2 grey--text text--lighten-5 "
      >
        <v-col class="pa-0">
          <div class="dividerLabel py-3">
            <p class="mb-0 px-5">担当者</p>
          </div>
        </v-col>
      </v-row>

      <v-row class="mx-5">
        <v-col class=" " cols="4">
          <v-text-field
            class="rounded-0"
            label="作業担当者"
            outlined
            clearable
            v-model="incharge"
          ></v-text-field>
        </v-col>

        <v-col class="" cols="4">
          <!-- <label>休憩時間①</label>
          <vue-timepicker
            class="ml-7"
            placeholder="休憩時間①"
            auto-scroll
            input-width="95%"
            :disabled="!edit"
          >
            <template v-slot:clearButton>
              <img src="@/assets/Clear.png" />
            </template>
          </vue-timepicker> -->

          <v-text-field
            class="rounded-0"
            label="休憩時間①"
            outlined
            clearable
          ></v-text-field>
        </v-col>

        <v-col class="" cols="4">
          <!-- <label>休憩時間②</label>
          <vue-timepicker
            class="ml-7"
            placeholder="休憩時間②"
            auto-scroll
            input-width="95%"
            :disabled="!edit"
          >
            <template v-slot:clearButton>
              <img src="@/assets/Clear.png" />
            </template>
          </vue-timepicker> -->

          <v-text-field
            class="rounded-0"
            label="休憩時間①"
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mx-5">
        <v-col class="" cols="4">
          <v-text-field
            class="rounded-0"
            label="実休憩時間"
            outlined
            clearable
            suffix="H"
            v-model="iActualBreak"
          ></v-text-field>
        </v-col>

        <v-col class="" cols="2">
          <v-checkbox v-model="iTimeCount" label="時間計算"> </v-checkbox>
        </v-col>

        <v-col class="" cols="3">
          <v-text-field
            class="rounded-0"
            label="作業時間"
            outlined
            clearable
            suffix="H"
            v-model="iWorkHours"
          ></v-text-field>
        </v-col>

        <v-col class="" cols="3">
          <v-text-field
            class="rounded-0"
            label="残業時間"
            outlined
            clearable
            suffix="H"
            v-model="iOvertimeHours"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- ***** bottom 2 full width textfields ######################################################################### -->
      <v-row class="mx-5">
        <v-col>
          <v-text-field
            class=" rounded-0"
            outlined
            clearable
            label="担当者変更内容"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mx-5">
        <v-col>
          <v-text-field
            class=" rounded-0"
            outlined
            clearable
            label="担当者変更理由"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- 作業内容 card 1 end ********************************* -->
    </div>
  </v-card>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: {
    "vue-timepicker": VueTimepicker
  },
  data() {
    return {
      edit: true,
      meetingTime: "08:50",
      startTime: "09:00",
      endTime: "15:00",
      companyTime: "16:00",
      workIncharge: "大阪 彰",
      wIActualBreak: "1.00",
      wIWorkHours: "5.00",
      wIOvertimeHours: "0.00",
      wITimeCount: true,
      incharge: "大阪 彰",
      iActualBreak: "1.00",
      iWorkHours: "5.00",
      iOvertimeHours: "0.00",
      iTimeCount: true,
      greeting: true,
      safetyConfirm: true,
      noticeBoard: true,
      coneChain: false
    };
  }
};
</script>

<style scoped>
.dividerLabel {
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

/* .workerForm .v-card__text {
  padding: 0;
} */
</style>
